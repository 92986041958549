import React from "react"
import {graphql, Link } from "gatsby"
import { Landing } from "../styles/Contact"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentWrapper } from "../styles/Wrapper"
import { useLanguage } from "../context/LanguageContext"
import { phoneIco, emailIco, printIco, houseIco } from "../utils/imageUpload"
import Observer from "@researchgate/react-intersection-observer"
import { useNav } from "../context"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"
import Map from "../components/ContactPage/Map"


const Contact = ({ data }) => {
  const { checkIntersection } = useNav()
  useSpecialPage(false)
  const [main] = data.allSanityContactpage.edges.map(({ node }) => node)
  const { language } = useLanguage()

  function chooseText(path, mainObj = main, lang = language) {
    return mainObj[`${path}_${lang}`]
  }



  return (
    <Layout>
      <SEO title={chooseText("sectionTitle", main, language)} />
      <Landing style={{ paddingTop: "var(--navHeight)" }}>

        <ContentWrapper>
          <div className="main-container">
            <div
              className="box"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-delay="100"
              data-sal-easing="ease"
            >
              <Observer
                rootMargin="-100px 0% -25%"
                onChange={checkIntersection}
              >
                <p className="sections title">{chooseText("sectionTitle")}</p>
              </Observer>

              <h2 className="headline">{chooseText("headline")}</h2>
              <div className="contact-info-container">
                <div className=" body contact-info">
                  <img src={phoneIco} alt="" />
                  <p>{main.phone}</p>
                </div>
                <div className=" body contact-info">
                  <img src={printIco} alt="" />
                  <p>{main.fax}</p>
                </div>
                <div className=" body contact-info">
                  <img src={emailIco} alt="" />
                  <p>{main.email}</p>
                </div>
                <div className=" body contact-info">
                  <img src={houseIco} alt="" />
                  <p>{main.address}</p>
                </div>
              </div>
            </div>
  
          </div>
        </ContentWrapper>
        <Link className="connect-map" to="/career/#map">
          <h3 style={{ color: "var(--blue)" }}>{"↓"}</h3>
        </Link>
      </Landing>
      <Map/>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    allSanityContactpage {
      edges {
        node {
          address
          button_de
          button_en
          company_de
          company_en
          dataProtect_de
          dataProtect_en
          email
          fax
          headline_de
          headline_en
          mandatory_de
          mandatory_en
          message_de
          message_en
          name_de
          name_en
          phone
          phone_de
          phone_en
          sectionTitle_de
          sectionTitle_en
        }
      }
    }
  }
`
